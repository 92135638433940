.input-texfield {
    margin-bottom: 5% !important;
}

.input-texfield:last-child {
    margin-bottom: 10px !important;
}

.input-texfield input {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #68717A !important;
    mix-blend-mode: normal !important;
    vertical-align: middle !important;
    padding: 0 16px 0 16px !important;
    height: 38px !important;
}

.icon input {
    padding: 0 16px 0 5px !important;
}

.input-texfield::placeholder {
    color: #ABB5BE !important;
}

.input-texfield p {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 17px;
    color: #DC3545;
    margin-top: 2% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.input-texfield textarea {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #68717A !important;
    mix-blend-mode: normal !important;
    vertical-align: middle !important;
}

.input-texfield .MuiFilledInput-multiline {
    padding: 16px 16px !important;
}

.input-texfield textarea::-webkit-scrollbar {
    display: none !important;
}

.input-texfield div, .input-texfield input:-webkit-autofill, .input-texfield input:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px #FFF inset;
    color: #68717A !important;
    -webkit-text-fill-color: #68717A !important;
}