.button-login {
    width: 94px !important;
    height: 42px !important;
    background-color: #5AB6DF !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    margin-top: 2% !important;
}

.button-login:hover {
    background-color: #54afd6 !important;
}

.link-login {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 28px !important;
    color: #442F91 !important;
    margin-top: 8% !important;
    text-decoration: none !important;
}